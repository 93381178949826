.home-container {
  font-family: 'Roboto', 'Arial', sans-serif;
  color: var(--text-dark);
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, var(--primary-light) 0%, var(--primary-color) 100%);
  position: relative;
  padding: 160px 0 120px;
  color: white;
  text-align: center;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0.5;
  z-index: -1;
}

.hero-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 25px;
  line-height: 1.2;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 1s ease-out;
}

.hero-subtitle {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 35px;
  opacity: 0.9;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  animation: fadeInUp 1s ease-out 0.3s;
  animation-fill-mode: both;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  animation: fadeInUp 1s ease-out 0.6s;
  animation-fill-mode: both;
}

.btn-primary {
  background-color: var(--accent-color);
  color: white;
  padding: 16px 36px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.btn-primary:hover {
  background-color: var(--accent-dark);
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.btn-secondary {
  background-color: transparent;
  color: white;
  padding: 14px 34px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid white;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Features Section */
.features-section {
  padding: 80px 0;
  background-color: var(--background-white);
}

.section-title {
  text-align: center;
  font-size: 32px;
  color: var(--primary-color);
  margin-bottom: 50px;
  font-weight: 600;
}

.features-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.feature-card {
  background-color: var(--background-light);
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px var(--shadow-color);
}

.feature-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  background-color: rgba(60, 116, 170, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  font-size: 32px;
}

.feature-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  color: var(--text-dark);
}

.feature-text {
  font-size: 16px;
  color: var(--text-light);
  line-height: 1.6;
}

/* About Section */
.about-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 50px;
}

.about-image {
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 30px var(--shadow-color);
}

.about-image img {
  width: 100%;
  height: auto;
  display: block;
}

.about-content {
  flex: 1;
}

.about-title {
  font-size: 32px;
  color: var(--primary-color);
  margin-bottom: 20px;
  font-weight: 600;
}

.about-text {
  font-size: 16px;
  line-height: 1.8;
  color: var(--text-light);
  margin-bottom: 25px;
}

/* Contact Section */
.contact-section {
  padding: 80px 0;
  background-color: var(--background-white);
}

.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.contact-form {
  background-color: var(--background-light);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 5px 15px var(--shadow-color);
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--text-dark);
}

.form-control {
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(60, 116, 170, 0.2);
}

.form-textarea {
  min-height: 120px;
  resize: vertical;
}

.btn-submit {
  background-color: var(--primary-color);
  color: white;
  padding: 14px 32px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  width: 100%;
}

.btn-submit:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px var(--shadow-color);
}

/* Footer */
.footer {
  background-color: #212121;
  color: white;
  padding: 60px 0 30px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}

.footer-logo {
  grid-column: span 4;
  margin-bottom: 30px;
}

.footer-logo img {
  height: 50px;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: white;
}

.footer-link {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: white;
}

.footer-contact p {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  color: rgba(255, 255, 255, 0.7);
}

.footer-social {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-icon {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: all 0.3s ease;
  font-size: 20px;
}

.social-icon:hover {
  background-color: var(--primary-color);
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.contact-phone {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-phone:hover {
  color: white;
  text-decoration: underline;
}

.footer-bottom {
  grid-column: span 4;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

/* Responsive */
@media (max-width: 992px) {
  .features-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .about-container {
    flex-direction: column;
  }
  
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 120px 0 80px;
  }
  
  .hero-title {
    font-size: 36px;
  }
  
  .hero-subtitle {
    font-size: 20px;
  }
  
  .btn-primary, .btn-secondary {
    padding: 14px 28px;
    font-size: 16px;
  }
  
  .features-container {
    grid-template-columns: 1fr;
  }
  
  .footer-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .footer-logo {
    grid-column: span 1;
  }
  
  .footer-bottom {
    grid-column: span 1;
  }
} 

/* Values Section */
.values-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.values-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.value-card {
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 10px var(--shadow-color);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.value-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--shadow-color);
}

.value-number {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: var(--primary-color);
  opacity: 0.3;
}

.value-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.value-text {
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-light);
}

/* Programs Section */
.programs-section {
  padding: 80px 0;
  background-color: var(--background-white);
}

.programs-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.programs-intro {
  text-align: center;
  margin-bottom: 40px;
  font-size: 18px;
  color: var(--text-light);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.programs-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 40px;
}

.program-item {
  display: flex;
  gap: 30px;
  background-color: var(--background-light);
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 10px var(--shadow-color);
  transition: all 0.3s ease;
}

.program-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--shadow-color);
}

.program-icon {
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
}

.program-content {
  flex: 1;
}

.program-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.program-intro {
  font-size: 18px;
  color: var(--text-dark);
  margin-bottom: 10px;
}

.program-text {
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-light);
}

.programs-outro {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color);
  margin-top: 40px;
}

/* Approach Section */
.approach-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.approach-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.approaches-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.approach-item {
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 10px var(--shadow-color);
}

.approach-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;
}

.approach-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: var(--primary-color);
}

.approach-text {
  font-size: 16px;
  line-height: 1.7;
  color: var(--text-light);
}

/* Activities Section */
.activities-section {
  padding: 80px 0;
  background-color: var(--background-white);
}

.activities-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.activities-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 50px;
}

.activity-item {
  display: flex;
  gap: 20px;
  background-color: var(--background-light);
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 10px var(--shadow-color);
  transition: all 0.3s ease;
}

.activity-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--shadow-color);
}

.activity-icon {
  font-size: 32px;
  display: flex;
  align-items: flex-start;
  min-width: 50px;
}

.activity-content {
  flex: 1;
}

.activity-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.activity-text {
  font-size: 15px;
  line-height: 1.6;
  color: var(--text-light);
}

.thematic-weeks {
  background-color: var(--background-light);
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 4px 10px var(--shadow-color);
}

.thematic-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.weeks-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.weeks-list li {
  margin-bottom: 12px;
  padding-left: 30px;
  position: relative;
  font-size: 16px;
  color: var(--text-dark);
}

.weeks-list li:before {
  content: '📅';
  position: absolute;
  left: 0;
  top: 0;
}

.thematic-outro {
  font-style: italic;
  color: var(--text-light);
}

.psychology-section {
  background-color: var(--background-light);
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 4px 10px var(--shadow-color);
}

.psychology-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.psychology-text {
  font-size: 16px;
  line-height: 1.7;
  color: var(--text-light);
}

.activities-outro {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color);
  margin-top: 40px;
}

/* Progress Section */
.progress-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.progress-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.progress-methods {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 40px;
}

.progress-method {
  background-color: var(--background-white);
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 10px var(--shadow-color);
  transition: all 0.3s ease;
}

.progress-method:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--shadow-color);
}

.method-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;
}

.method-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: var(--primary-color);
}

.method-text {
  font-size: 15px;
  line-height: 1.6;
  color: var(--text-light);
}

.progress-outro {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
  margin-top: 30px;
}

/* Responsive for new sections */
@media (max-width: 992px) {
  .values-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .activities-list {
    grid-template-columns: 1fr;
  }
  
  .progress-methods {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .values-container {
    grid-template-columns: 1fr;
  }
  
  .program-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .program-icon {
    margin-bottom: 15px;
  }
  
  .activities-list {
    grid-template-columns: 1fr;
  }
  
  .activity-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .activity-icon {
    margin-bottom: 15px;
  }
} 