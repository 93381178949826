.offer-container {
  max-width: 1200px;
  margin: 100px auto 50px;
  padding: 0 20px;
  font-family: 'Roboto', 'Arial', sans-serif;
  line-height: 1.6;
  color: var(--text-dark);
}

.offer-content {
  background-color: var(--background-white);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px var(--shadow-color);
}

.offer-title {
  font-size: 28px;
  color: var(--primary-color);
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
}

.offer-intro {
  margin-bottom: 30px;
  font-style: italic;
  color: var(--text-light);
  font-size: 16px;
  border-left: 3px solid var(--primary-color);
  padding-left: 15px;
  background-color: var(--background-light);
  padding: 15px;
  border-radius: 0 4px 4px 0;
}

.offer-section {
  margin-bottom: 35px;
}

.section-title {
  font-size: 20px;
  color: var(--primary-color);
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
  font-weight: 500;
}

.terms-list {
  padding-left: 25px;
  margin-bottom: 20px;
}

.terms-list li {
  margin-bottom: 12px;
  position: relative;
}

.terms-list strong {
  color: var(--primary-color);
  font-weight: 500;
}

ul {
  list-style-type: disc;
  margin-bottom: 15px;
}

ul li {
  margin-bottom: 10px;
}

.contact-email {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.contact-email:hover {
  text-decoration: underline;
  color: var(--primary-dark);
}

/* Add some spacing between paragraphs */
p {
  margin-bottom: 15px;
  line-height: 1.7;
}

/* Ensure proper contrast and readability */
strong {
  font-weight: 500;
  color: var(--text-dark);
}

/* Responsive styles */
@media (max-width: 768px) {
  .offer-container {
    margin-top: 80px;
    padding: 0 15px;
  }

  .offer-content {
    padding: 25px 20px;
  }

  .offer-title {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 18px;
  }
  
  .offer-intro {
    font-size: 15px;
    padding: 12px;
  }
} 