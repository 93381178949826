/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* Color variables */
:root {
  --primary-color: #3C74AA; /* Основной синий цвет Restart */
  --primary-dark: #2A5682; /* Темный синий для hover-эффектов */
  --primary-light: #6299D0; /* Светлый синий для фонов */
  --accent-color: #FF9800; /* Оранжевый акцент */
  --accent-dark: #E67E00; /* Темный оранжевый */
  --text-dark: #333333; /* Основной цвет текста */
  --text-light: #757575; /* Легкий текст */
  --background-light: #f8f9fa; /* Светлый фон */
  --background-white: #ffffff; /* Белый фон */
  --border-color: #e0e0e0; /* Цвет границ */
  --shadow-color: rgba(0, 0, 0, 0.1); /* Цвет теней */
}

/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-light);
  color: var(--text-dark);
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.5em;
  color: var(--primary-dark);
}

p {
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Common utility classes */
.text-center {
  text-align: center;
}

.mt-1 { margin-top: 0.25rem; }
.mt-2 { margin-top: 0.5rem; }
.mt-3 { margin-top: 1rem; }
.mt-4 { margin-top: 1.5rem; }
.mt-5 { margin-top: 2rem; }

.mb-1 { margin-bottom: 0.25rem; }
.mb-2 { margin-bottom: 0.5rem; }
.mb-3 { margin-bottom: 1rem; }
.mb-4 { margin-bottom: 1.5rem; }
.mb-5 { margin-bottom: 2rem; }

/* Set page transition */
.page-transition {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Content spacing for fixed navbar */
main {
  padding-top: 80px;
}

/* Глобальные стили */
body {
  margin: 0;
  font-family: 'Roboto', 'Arial', sans-serif;
  background-color: #f8f9fa;
  color: #1a1a1a;
}

h1, h2, h3, h4 {
  color: #003366; /* navy blue акцент */
  margin-bottom: 20px;
}

nav {
  background-color: #003366; /* navy blue */
  color: white;
  padding: 15px 30px;
}

nav a {
  color: white;
  font-weight: bold;
  margin-right: 30px;
  transition: opacity 0.3s ease;
}

nav a:hover {
  opacity: 0.8;
}

.container {
  max-width: 960px;
  margin: 40px auto;
  padding: 0 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  padding: 40px;
}
