.navbar {
  background-color: var(--background-white);
  box-shadow: 0 2px 10px var(--shadow-color);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 10px 0;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.navbar-logo img {
  height: 50px;
  object-fit: contain;
}

.navbar-menu {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navbar-item {
  text-decoration: none;
  color: var(--text-dark);
  font-weight: 500;
  font-size: 16px;
  transition: color 0.3s ease;
  position: relative;
}

.navbar-item:hover {
  color: var(--primary-color);
}

.navbar-item::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

.navbar-item:hover::after {
  width: 100%;
}

/* Language Switcher */
.language-switcher {
  display: flex;
  align-items: center;
  margin-left: 15px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
}

.language-btn {
  background: none;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--text-light);
}

.language-btn.active {
  background-color: var(--primary-color);
  color: white;
}

.language-btn:not(.active):hover {
  background-color: var(--background-light);
}

.navbar-mobile-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.navbar-mobile-toggle span {
  width: 25px;
  height: 3px;
  background-color: var(--text-dark);
  margin: 3px 0;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .navbar-menu {
    position: fixed;
    top: 80px;
    left: -100%;
    flex-direction: column;
    background-color: var(--background-white);
    width: 100%;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 2px 4px var(--shadow-color);
    padding: 20px 0;
  }

  .navbar-menu.active {
    left: 0;
  }

  .navbar-mobile-toggle {
    display: flex;
  }

  .navbar-item {
    margin: 10px 0;
  }
  
  .language-switcher {
    margin: 15px auto 0;
    width: fit-content;
  }
} 